import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { toPlainText } from '@portabletext/react';
import { useIntersectionObserver } from '@react-hookz/web';

import { Accordion } from '@/components/common/Accordion/Accordion';
import { Badge } from '@/components/common/Badge';
import { breakpoints, screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { Image } from '@/components/common/image';
import { HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import {
  getChildBackgroundColourToken,
  getTextColour,
} from '@/lib/colourUtils';
import { useMatchMedia } from '@/lib/useMatchMedia';
import { colourTokens } from '@/styles/colours';
import { bodyLarge, headingMedium, subheadingSmall } from '@/styles/typography';

import type { DefaultAccordionItemProps } from '@/components/common/Accordion/DefaultAccordionItem';
import type {
  BackgroundColourTokenType,
  SurfaceColourTokenType,
  TextColourTokenType,
} from '@/types/colours';
import type { SanityAccordionWithImageType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface AccordionWithImageProps
  extends Omit<SanityAccordionWithImageType, 'accordion'>,
    BlockWithAudience {
  accordion: DefaultAccordionItemProps[];
}

export const AccordionWithImage = ({
  tag,
  headingCaption,
  heading,
  subheading,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
  primaryImage,
  accordion,
  isCaptionUppercase = false,
  imagePosition = 'left',
  pageAudience,
  isAudienceSwitcherEnabled,
  timer,
}: AccordionWithImageProps) => {
  const accordionRef = useRef(null);
  const [accordionHeight, setAccordionHeight] = useState(300);
  const isMobile = useMatchMedia(breakpoints.md);
  const isPrimaryImageSet = primaryImage && !!primaryImage.asset?._ref;
  const backgroundColourToken =
    backgroundColour?.token || colourTokens.background.primary.token;
  const childBackgroundColourToken = getChildBackgroundColourToken(
    backgroundColourToken,
    colourTokens.surface.subtle.token,
  );
  const [openItems, setOpenItems] = useState([0]);

  useEffect(() => {
    if (accordionRef.current) {
      const { height } = accordionRef.current.getBoundingClientRect();

      // Get the height of the element with the longest body

      // First accordion item
      const mockedAccordionItem = accordionRef.current.querySelector(
        'ul > li > div > div > div > p',
      );

      const { height: mockedAccordionItemHeight } =
        mockedAccordionItem.getBoundingClientRect();
      const longestBodyAccordionItem = accordion.reduce(
        function (accordionItem1, accordionItem2) {
          return toPlainText(accordionItem1.body).length >
            toPlainText(accordionItem2.body).length
            ? accordionItem1
            : accordionItem2;
        },
      );
      mockedAccordionItem.replaceChildren(
        toPlainText(longestBodyAccordionItem.body),
      );
      const { height: longestBodyElementHeight } =
        mockedAccordionItem.getBoundingClientRect();

      // Building paddings
      accordion.forEach((accordionItem) => {
        mockedAccordionItem.replaceChildren(toPlainText(accordionItem.body));

        const { height } = mockedAccordionItem.getBoundingClientRect();
        accordionItem.accordionItemPadding = longestBodyElementHeight - height;
      });

      setAccordionHeight(
        height - mockedAccordionItemHeight + longestBodyElementHeight,
      );
    }
  }, []);

  accordion.map((item) => (item.primaryImage = primaryImage));

  const accordionIntersection = useIntersectionObserver(accordionRef);

  const accordionIsInView = accordionIntersection?.isIntersecting;

  const imageSelection = !isPrimaryImageSet ? (
    <>
      {openItems.length === 0 && accordion[0]?.itemImage?.asset && (
        <ImageWrapper accordionHeight={accordionHeight}>
          <Surface
            surfaceColour="--surface-warm-base"
            borderRadius="--radius-component-md"
            fill
            padding={false}
          >
            <Image
              {...accordion[0]?.itemImage}
              alt={accordion[0]?.itemImage?.alternateText}
              objectFit="cover"
            />
          </Surface>
        </ImageWrapper>
      )}
      {openItems.length >= 1 && accordion[openItems[0]]?.itemImage?.asset && (
        <ImageWrapper accordionHeight={accordionHeight}>
          <Surface
            surfaceColour="--surface-warm-base"
            borderRadius="--radius-component-md"
            fill
            padding={false}
          >
            <Image
              {...accordion[openItems[0]]?.itemImage}
              alt={accordion[openItems[0]]?.itemImage?.alternateText}
              objectFit="cover"
              sizes="(max-width: 960px) 100vw, 50vw"
            />
          </Surface>
        </ImageWrapper>
      )}
    </>
  ) : (
    <ImageWrapper accordionHeight={accordionHeight}>
      <Surface
        surfaceColour="--surface-warm-base"
        borderRadius="--radius-component-md"
        fill
        padding={false}
      >
        <Image
          {...primaryImage}
          alt={primaryImage.alternateText}
          objectFit="cover"
        />
      </Surface>
    </ImageWrapper>
  );

  return (
    <Section
      backgroundColour={backgroundColourToken}
      colour={getTextColour(backgroundColourToken)}
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      className="accordion-with-image"
    >
      <Row>
        <Col
          sizes={{ lg: 10, md: 12, sm: 10, base: 4 }}
          start={{ xl: 2, lg: 2, md: 1, sm: 2 }}
        >
          {tag?.tagText && (
            <Badge
              bgColour={tag?.tagColour}
              margin="0 0 var(--spacing-xxx-small) 0"
            >
              {tag.tagText}
            </Badge>
          )}
          <HeadingLevel>
            {(heading || headingCaption || subheading) && (
              <StyledCaptionHeadingDescription
                caption={headingCaption}
                heading={heading}
                description={subheading}
                isCaptionUppercase={isCaptionUppercase}
                backgroundColourToken={backgroundColourToken}
                pageAudience={pageAudience}
                isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
              />
            )}
            <AccordionWrapper
              backgroundColourToken={childBackgroundColourToken}
            >
              {imagePosition === 'left' && !isMobile && imageSelection}
              <StyledAccordion ref={accordionRef} imagePosition={imagePosition}>
                <Accordion
                  accordionBehaviour="singleItem"
                  accordionItems={accordion}
                  openItemsState={[openItems, setOpenItems]}
                  pageAudience={pageAudience}
                  isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                  timer={timer}
                  isInView={accordionIsInView}
                />
              </StyledAccordion>
              {imagePosition === 'right' && !isMobile && imageSelection}
            </AccordionWrapper>
          </HeadingLevel>
        </Col>
      </Row>
    </Section>
  );
};

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)<{
  backgroundColourToken: BackgroundColourTokenType;
  textColour?: TextColourTokenType;
}>`
  margin-bottom: var(--spacing-x-large);

  ${Paragraph} {
    ${subheadingSmall}
  }
`;

const StyledAccordion = styled.div<{ imagePosition: 'left' | 'right' }>`
  height: fit-content;
  padding: var(--space-component-sm) var(--space-component-xl);

  ${screen.sm} {
    padding: var(--space-component-md) var(--space-component-xxl);
  }

  ${screen.md} {
    padding: ${({ imagePosition }) =>
      imagePosition === 'left'
        ? `var(--space-component-md) var(--space-component-xxl) var(--space-component-md) 0`
        : `var(--space-component-md) 0 var(--space-component-md)
      var(--space-component-xxl)`};
  }
  ${screen.lg} {
    padding: ${({ imagePosition }) =>
      imagePosition === 'left'
        ? `var(--space-component-lg) var(--space-component-xxl) var(--space-component-lg) 0`
        : `var(--space-component-lg) 0 var(--space-component-lg)
      var(--space-component-xxl)`};
  }

  ul {
    border-radius: var(--radius-m);

    li {
      padding: 19px 0;
      display: grid;
    }
    li:not(:last-child) {
      border-bottom: 1px solid var(--border-warm-subtle);
    }

    span {
      ${headingMedium}
    }

    p {
      ${bodyLarge}
      padding-right: calc(var(--space-component-md) + 28px);
    }
  }
`;

const AccordionWrapper = styled.div<{
  backgroundColourToken: BackgroundColourTokenType | SurfaceColourTokenType;
}>`
  position: relative;
  align-items: center;

  border-radius: var(--radius-component-xl);
  border: 1px solid var(--border-warm-base);

  ${({ backgroundColourToken }) =>
    backgroundColourToken && `background-color: var(${backgroundColourToken});`}

  ${screen.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-component-xl);
  }
`;

const ImageWrapper = styled.div<{
  accordionHeight: number;
}>`
  padding: var(--space-component-sm);
  height: 100%;

  img {
    height: ${({ accordionHeight }) => `${accordionHeight - 16}`}px;
    border-radius: var(--radius-component-md);
  }
  ${screen.md} {
    display: block;
  }
`;
